import { DefaultLogMessage, log } from ".";
import { getChangeSettingEndlessPlayData, getChangeSettingLanguageSettingData, getChangeSettingThemeSettingData } from "./analytics/changeSetting";
import {
    getAnalyticsLogInEvent,
    getAnalyticsLogOutEvent,
    getAnalyticsPlayedContentEvent,
    getAnalyticsMyMusicChangedEvent,
    getAnalyticsPlaylistCreatedEvent,
    getAnalyticsPlaylistDeletedEvent,
    getAnalyticsFeedNotificationsSeenEvent,
    getAnalyticsPlayedContentErrorEvent,
    getAnalyticsSearchContentEvent,
    getAnalyticsSingleNotificationConvertedEvent,
    getAnalyticsSingleNotificationSeenEvent,
    getAnalyticsViewPageEvent,
    getAddToPlaylistEvent,
    getAnalyticsSentryCutoffEvent
} from "./analytics/events";
import { getAnalyticsLyricsDisplayedEvent } from "./analytics/events/lyricsDisplayed";
import { initPlayerActionAnalyticsService } from "./analytics/playerAction/playerActionService";
import { initQueueActionAnalyticsService } from "./analytics/queueAction/queueActionService";
import { dispatch, messageBus, store } from "global";
import { RouterRedirectingAction, SENTRY_CUTOFF_SHOWN } from "global/actions";
import {
    ANALYTICS_PLAYED_ERROR_EVENT,
    ANALYTICS_PLAYED_EVENT,
    FAVORITES_UPDATED,
    LOGGING_EVENT_ANALYTICS,
    LOGGING_EVENT_PLAYBACK_REPORT_FINISHED,
    NAVIGATION_NAVIGATED,
    NOTIFICATION_FEED_NOTIFICATIONS_SEEN,
    NOTIFICATION_FEED_NOTIFICATION_CONVERTED,
    PLAYLIST_CREATED,
    PLAYLIST_DELETED,
    PLAYQUEUE_ENDLESSPLAY_TOGGLE,
    SET_LOCALIZATION_SETTING,
    SET_THEME_SETTING,
    ROUTER_REDIRECTING,
    USER_CLICK_SEARCH_RESULT,
    USER_LOGGED_IN,
    USER_LOGGING_OUT,
    PLAYLIST_COLLECTION_ADDED,
    PLAYLIST_TRACKS_ADDED,
    PLAYLIST_TRACK_ADDED,
    PLAYLIST_CANCELLED_ADDING_DUPLICATE_ITEMS,
    DISPLAYED_LYRICS,
    PROFILE_CREATED,
    PROFILE_UPDATED,
    PROFILE_DELETED
} from "global/actions";
import type { AnalyticsKey } from "global/config";
import { onPageContextReady } from "services/navigationStack/navigationStack";
import { getNotificationFeedFromState } from "services/notificationFeed";
import { LoginState } from "models/app";
import type { PlayQueuePlayableModel } from "models/domain";
import { getCreateProfileEvent, getDeleteProfileEvent, getEditProfileEvent } from "./analytics/events/profileActions";

export const initAnalyticsService = () => {
    initPlayerActionAnalyticsService();
    initQueueActionAnalyticsService();
    let redirect: RouterRedirectingAction["payload"] | null = null;

    messageBus.subscribeEvery(NOTIFICATION_FEED_NOTIFICATIONS_SEEN, async (msg) => {
        const { seen, current } = msg.payload;

        const feed = getNotificationFeedFromState();
        if (!feed.notificationState) return;

        const data = await getAnalyticsFeedNotificationsSeenEvent(current, seen);
        dispatch({ type: LOGGING_EVENT_ANALYTICS, payload: { name: "NotificationsSeen", data } });

        seen.forEach((n) =>
            (async () => {
                const data = await getAnalyticsSingleNotificationSeenEvent(current, n);
                dispatch({ type: LOGGING_EVENT_ANALYTICS, payload: { name: "SingleNotificationSeen", data } });
            })()
        );
    });

    messageBus.subscribeEvery(NOTIFICATION_FEED_NOTIFICATION_CONVERTED, async (msg) => {
        const { converted, conversion } = msg.payload;

        const feed = getNotificationFeedFromState();
        if (!feed) return;

        const data = await getAnalyticsSingleNotificationConvertedEvent(converted, conversion);
        dispatch({ type: LOGGING_EVENT_ANALYTICS, payload: { name: "SingleNotificationConverted", data } });
    });

    messageBus.subscribeEvery(ANALYTICS_PLAYED_EVENT, async (msg) => {
        const { queueData, loadData, stopData, playableData } = msg.payload;

        const data = await getAnalyticsPlayedContentEvent(playableData, queueData, loadData, stopData);
        if (!data) {
            log.error({ code: "web-220209-1546", msg: DefaultLogMessage.UnexpectedNull });
            return;
        }
        dispatch({ type: LOGGING_EVENT_ANALYTICS, payload: { name: "PlayedContent", data } });
    });

    messageBus.subscribeEvery(ANALYTICS_PLAYED_ERROR_EVENT, async (msg) => {
        const { queueData, loadData, errorData, playableData } = msg.payload;

        const data = await getAnalyticsPlayedContentErrorEvent(playableData, queueData, loadData, errorData);
        if (!data) {
            log.error({ code: "web-220209-1546", msg: DefaultLogMessage.UnexpectedNull });
            return;
        }
        dispatch({ type: LOGGING_EVENT_ANALYTICS, payload: { name: "PlayedContentError", data } });
    });

    messageBus.subscribeEvery(PLAYQUEUE_ENDLESSPLAY_TOGGLE, async () => {
        const data = await getChangeSettingEndlessPlayData();
        dispatch({ type: LOGGING_EVENT_ANALYTICS, payload: { name: "ChangeSetting", data } });
    });

    messageBus.subscribeEvery(SET_THEME_SETTING, async (msg) => {
        const data = await getChangeSettingThemeSettingData(msg);
        dispatch({ type: LOGGING_EVENT_ANALYTICS, payload: { name: "ChangeSetting", data } });
    });

    messageBus.subscribeEvery(SET_LOCALIZATION_SETTING, async (msg) => {
        const data = await getChangeSettingLanguageSettingData(msg);
        dispatch({ type: LOGGING_EVENT_ANALYTICS, payload: { name: "ChangeSetting", data } });
    });

    messageBus.subscribeEvery(PLAYLIST_CREATED, async (msg) => {
        const { playlist, playlistId } = msg.payload;
        const data = await getAnalyticsPlaylistCreatedEvent(playlistId, playlist);
        dispatch({ type: LOGGING_EVENT_ANALYTICS, payload: { name: "CreatePlaylist", data } });
    });

    messageBus.subscribeEvery(PLAYLIST_DELETED, async (msg) => {
        const { playlistId } = msg.payload;
        const data = await getAnalyticsPlaylistDeletedEvent(playlistId);
        dispatch({ type: LOGGING_EVENT_ANALYTICS, payload: { name: "DeletePlaylist", data } });
    });

    messageBus.subscribeEvery(PLAYLIST_COLLECTION_ADDED, async (msg) => {
        const { playlist, playlistId, playable, trackCount, duplicateTracksAdded } = msg.payload;
        const data = await getAddToPlaylistEvent(playlistId, playlist, playable, trackCount, duplicateTracksAdded);
        dispatch({ type: LOGGING_EVENT_ANALYTICS, payload: { name: "AddToPlaylist", data } });
    });

    messageBus.subscribeEvery(PLAYLIST_TRACKS_ADDED, async (msg) => {
        const { playlist, playlistId, tracks, duplicateTracksAdded } = msg.payload;
        const data = await getAddToPlaylistEvent(playlistId, playlist, null, tracks?.length ?? 0, duplicateTracksAdded);
        dispatch({ type: LOGGING_EVENT_ANALYTICS, payload: { name: "AddToPlaylist", data } });
    });

    messageBus.subscribeEvery(PLAYLIST_TRACK_ADDED, async (msg) => {
        const { playlist, playlistId, track, duplicateTracksAdded } = msg.payload;
        const data = await getAddToPlaylistEvent(playlistId, playlist, track ?? null, 1, duplicateTracksAdded);
        dispatch({ type: LOGGING_EVENT_ANALYTICS, payload: { name: "AddToPlaylist", data } });
    });

    messageBus.subscribeEvery(PLAYLIST_CANCELLED_ADDING_DUPLICATE_ITEMS, async (msg) => {
        const { playlist, playlistId, trackCount, playable } = msg.payload;
        const data = await getAddToPlaylistEvent(playlistId, playlist, playable, trackCount, "cancel");
        dispatch({ type: LOGGING_EVENT_ANALYTICS, payload: { name: "AddToPlaylist", data } });
    });

    messageBus.subscribeEvery(PROFILE_CREATED, async (msg) => {
        let data = await getCreateProfileEvent(msg.payload.profile, msg.payload.entrypoint);
        dispatch({ type: LOGGING_EVENT_ANALYTICS, payload: { name: "CreateProfile", data } });
    });

    messageBus.subscribeEvery(PROFILE_UPDATED, async (msg) => {
        let data = await getEditProfileEvent(msg.payload.profile, msg.payload.oldProfile);
        dispatch({ type: LOGGING_EVENT_ANALYTICS, payload: { name: "EditProfile", data } });
    });

    messageBus.subscribeEvery(PROFILE_DELETED, async (msg) => {
        let data = await getDeleteProfileEvent(msg.payload.profile);
        dispatch({ type: LOGGING_EVENT_ANALYTICS, payload: { name: "DeleteProfile", data } });
    });

    messageBus.subscribeEvery(FAVORITES_UPDATED, (msg) => {
        const { operation, playables } = msg.payload.changes;

        const key: AnalyticsKey = operation === "add" ? "AddToMyMusic" : "RemoveFromMyMusic";
        playables.forEach(async (playable) => {
            const data = await getAnalyticsMyMusicChangedEvent(msg.payload.changes.operation, playable as PlayQueuePlayableModel);
            dispatch({ type: LOGGING_EVENT_ANALYTICS, payload: { name: key, data } });
        });
    });

    messageBus.subscribeEvery(USER_LOGGED_IN, (msg) => {
        if (!msg.payload.isNewUser) return;
        onPageContextReady(async () => {
            const data = await getAnalyticsLogInEvent();
            dispatch({ type: LOGGING_EVENT_ANALYTICS, payload: { name: "LogIn", data } });
        });
    });

    messageBus.subscribeEvery(SENTRY_CUTOFF_SHOWN, async (msg) => {
        const data = await getAnalyticsSentryCutoffEvent(msg.payload.action);
        dispatch({
            type: LOGGING_EVENT_ANALYTICS,
            payload: {
                name: "SentryCutOffNotificationClicked",
                data
            }
        });
    });

    messageBus.subscribeEvery(USER_LOGGING_OUT, async (msg) => {
        const data = await getAnalyticsLogOutEvent(msg);
        dispatch({ type: LOGGING_EVENT_ANALYTICS, payload: { name: "LogOut", data } });
    });

    messageBus.subscribeEvery(LOGGING_EVENT_PLAYBACK_REPORT_FINISHED, (msg) => {
        const analytics = msg.payload.report.analytics2;
        if (!analytics) {
            // TODO re-enable this log later
            // log.error({code: "web-210929-2106", msg: DefaultLogMessage.UnexpectedNull});
            return;
        }
        dispatch({ type: ANALYTICS_PLAYED_EVENT, payload: { ...analytics } });
    });

    const onNavigation = () => {
        onPageContextReady(async (payload) => {
            const redirectFrom = redirect?.to === window.location.pathname ? redirect.from : null;
            redirect = null;
            const data = await getAnalyticsViewPageEvent(payload.error ?? null, redirectFrom);
            dispatch({ type: LOGGING_EVENT_ANALYTICS, payload: { name: "ViewPage", data } });
        });
    };
    onNavigation();

    messageBus.subscribeEvery(NAVIGATION_NAVIGATED, () => {
        onNavigation();
    });

    messageBus.subscribeEvery(ROUTER_REDIRECTING, (msg) => {
        redirect = msg.payload;
    });

    messageBus.subscribeEvery(USER_CLICK_SEARCH_RESULT, async (msg) => {
        const { criterion, source, preview } = msg.payload;
        const data = await getAnalyticsSearchContentEvent(criterion, source, preview);
        dispatch({ type: LOGGING_EVENT_ANALYTICS, payload: { name: "SearchContent", data } });
    });

    messageBus.subscribeEvery(DISPLAYED_LYRICS, async (msg) => {
        const { playable, lyricsId, startingPoint, sync } = msg.payload;
        if (!playable) return;
        const reducedStartingPoint = startingPoint === "DesktopPlayer" ? "MaxiPlayer" : startingPoint;
        const data = await getAnalyticsLyricsDisplayedEvent(playable, lyricsId, reducedStartingPoint, sync);
        dispatch({ type: LOGGING_EVENT_ANALYTICS, payload: { name: "LyricsDisplayed", data } });
    });
};

export function allowUserAnalytics(): boolean {
    const state = store.getState().user.state;

    switch (state) {
        case LoginState.Unknown:
            log.error({ code: "web-210914-1633", msg: "unknown user state while trying to log" });
            return false;
        case LoginState.LoggedOut:
            return false;
        case LoginState.LoggingOut:
        case LoginState.LoggedIn:
        case LoginState.TemporaryError:
            return true;
    }
}
