import { h } from "preact";
import { useTranslations } from "global/config";
import { IconName } from "components/atoms/icon";
import { AccordionLink } from "./AccordionLink";
import { useForceLogoutUrl } from "services/backend";
import { useCallback } from "preact/hooks";

export const ForceLogoutSettings = () => {
    const translations = useTranslations();

    const { model } = useForceLogoutUrl({});

    const goToForceLogout = useCallback(() => {
        window.open(model || "", "_blank", "noopener ");
    }, [model]);

    return <AccordionLink icon={IconName.SignOut} title={translations.SettingsForceLogoutTitle} onClick={goToForceLogout} />;
};
