import type { SentryCutoffAction } from "global/actions";
import { AnalyticsData, combineAnalyticsData, createAnalyticsData, IncrementAnalyticsProp, SetAnalyticsProp, SetFirstAnalyticsProp } from "services/logger/analytics/analyticsData";
import { convertAnalyticsAppProperties, getAnalyticsAppProperties } from "services/logger/analytics";
import { getAnalyticsUserProperties } from "services/logger/analytics/properties/user";

export function getAnalyticsSentryCutoffProperties(action: SentryCutoffAction) {
    const data = createAnalyticsData();

    SetFirstAnalyticsProp(data, "user", "SentryCutOffNotificationFirstClicked", new Date());
    SetAnalyticsProp(data, "user", "SentryCutOffNotificationAction", action);
    IncrementAnalyticsProp(data, "user", "SentryCutOffNotificationClickCount");

    return { ...data, type: "SentryCutoffNotificationClicked" };
}

export async function getAnalyticsSentryCutoffEvent(action: SentryCutoffAction): Promise<AnalyticsData> {
    const data = await combineAnalyticsData(convertAnalyticsAppProperties(getAnalyticsAppProperties()), getAnalyticsUserProperties(), getAnalyticsSentryCutoffProperties(action));
    return data;
}
