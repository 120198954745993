import { dismissModal, Modal, openModal } from "components/organisms/modal";
import { ButtonDesign } from "components/atoms/controls/button";
import { h } from "preact";
import { useTranslations } from "global/config";
import { useInviteFlowHelpUrl } from "services/backend";
import { getCookie, setCookie } from "services/cookies";
import { NOTIFICATION_BANNER_TOGGLE, SENTRY_CUTOFF_SHOWN } from "global/actions";
import { dispatch } from "global";
import { BannerWarning } from "models/app";

export const INVITE_NEEDED_MODAL_PROMPTED = "inviteNeededPrompted";
export const showInviteFlowModal = () => {
    const gracePeriod = 1000 * 60 * 60 * 24 * 3;
    const lastInvite = getCookie(INVITE_NEEDED_MODAL_PROMPTED);
    const lastInviteDate = new Date(lastInvite ?? "");

    dispatch({ type: NOTIFICATION_BANNER_TOGGLE, payload: { warning: BannerWarning.SentryCutoff, show: true } });

    if (new Date().getTime() > gracePeriod + lastInviteDate.getTime() || !lastInvite) {
        setCookie(INVITE_NEEDED_MODAL_PROMPTED, new Date().toString());
        return openModal(<InviteFlowModal />);
    }
    return null;
};

const InviteFlowModal = () => {
    const translations = useTranslations();
    const { model: inviteFlowReadMoreLink } = useInviteFlowHelpUrl({});

    return (
        <Modal
            buttons={[
                {
                    title: "SentryCutOffRemindLaterOption",
                    action: () => {
                        dispatch({
                            type: SENTRY_CUTOFF_SHOWN,
                            payload: {
                                action: "SentryCutOffRemindMeLaterAction"
                            }
                        });
                        dismissModal("InviteFlowModal");
                    },
                    design: ButtonDesign.TextBrandMedium
                },
                {
                    title: "SentryCutOffReadMoreOption",
                    action: () => {
                        window.open(inviteFlowReadMoreLink as string, "_blank");
                        dispatch({
                            type: SENTRY_CUTOFF_SHOWN,
                            payload: {
                                action: "SentryCutOffReadMoreAction"
                            }
                        });
                        dismissModal("InviteFlowModal");
                    },
                    design: ButtonDesign.PrimarySmall
                }
            ]}
            title={translations["SentryCutOffTitle"]}>
            <div className="text">
                <p>{translations["SentryCutOffSubtitle"]}</p>
            </div>
        </Modal>
    );
};

export default InviteFlowModal;
