// todo: move this to ControlPanelModel with console.settings support, and consider fetching it from the server

declare const WEBPACK_FORCE_REMOTE_BACKEND: boolean;

export enum Feature {
    ClientsideFiltering,
    Multiselect,
    LiveradioHeartbeat,
    APQ,
    SessionFrame,
    ForceRemoteBackend,
    Lyrics,
    LyricsIconWhenNotAvailable,
    Profiles,
    SoundQuality,
    BottomSheetMenus,
    ForceLogout
}

export function isFeatureEnabled(feature: Feature) {
    switch (feature) {
        case Feature.LiveradioHeartbeat:
            return true;
        case Feature.ClientsideFiltering:
            return false;
        case Feature.Multiselect:
            return false;
        case Feature.APQ:
            return false;
        case Feature.SessionFrame:
            // only enable on stage, where the backend also enables ping login
            return -1 !== document.location.hostname.indexOf("stage");
        case Feature.ForceRemoteBackend: {
            const isLocalhost = document.location.hostname === "localhost";
            return isLocalhost && !!WEBPACK_FORCE_REMOTE_BACKEND;
        }
        case Feature.Lyrics:
            return true;
        case Feature.LyricsIconWhenNotAvailable:
            return false;
        case Feature.Profiles:
            return true;
        case Feature.SoundQuality:
            return false;
        case Feature.BottomSheetMenus:
            return false;
        case Feature.ForceLogout:
            return true;
    }
}
