import { Fragment, h } from "preact";
import "./SettingsPage.scss";
import { FeedbackSettings } from "./FeedbackSettings";
import { LanguageSettings } from "./LanguageSettings";
import { PlaybackSettings } from "./PlaybackSettings";
import { SignOutSettings } from "./SignOutSettings";
import { ThemeSettings } from "./ThemeSettings";
import { VersionSettings } from "./VersionSettings";
import { ContentType } from "models/ModelType";
import { usePageContext } from "components/shared/hooks";
import { PageTopBar } from "components/molecules/pageTopBar";
import { HelpSettings } from "./HelpSettings";
import { SoundQualitySettings } from "./SoundQualitySettings";
import { UsernameSetting } from "./UsernameSetting";
import { LoginState } from "models/app";
import { useLoginState } from "services/user";
import { ForceLogoutSettings } from "components/pages/settingsPage/ForceLogoutSettings";
import { Feature, isFeatureEnabled } from "global/config";

export const SettingsPage = () => {
    const page = usePageContext({ type: ContentType.SettingsPage, resource: null, root: null, done: true });
    const isLoggedIn = useLoginState() === LoginState.LoggedIn;

    return (
        <div className="settingsPage s6r">
            <div className="scroll">
                <PageTopBar page={page} />
                <section className={`section`}>
                    <PlaybackSettings />
                    <hr />
                    <ThemeSettings />
                    <hr />
                    {isLoggedIn && (
                        <Fragment>
                            <SoundQualitySettings />
                            <hr />
                        </Fragment>
                    )}
                    <LanguageSettings />
                    <hr />
                    <HelpSettings />
                    {isFeatureEnabled(Feature.ForceLogout) && (
                        <>
                            <hr />
                            <ForceLogoutSettings />
                        </>
                    )}
                    {isLoggedIn && (
                        <Fragment>
                            <hr />
                            <FeedbackSettings />
                            <hr />
                            <SignOutSettings />
                            <UsernameSetting />
                        </Fragment>
                    )}
                    <VersionSettings />
                </section>
            </div>
        </div>
    );
};
