export function setCookie(name: string, value: string, expires?: Date, domain?: string) {
    if (!expires) expires = new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000);

    let cookie = `${name}=${value}; expires=${expires.toUTCString()}; path=/`;
    if (domain) cookie = cookie += `; domain=${domain}`;

    document.cookie = cookie;
}

export function getCookie(name: string): string | null {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);

    if (parts.length == 2) {
        return parts.pop()?.split(";").shift() ?? null;
    }

    return null;
}

export function deleteCookie(name: string, domain?: string) {
    const expires = new Date(0);
    setCookie(name, "", expires, domain);
}
