import type {
    AnalyticsPlayedErrorEventAction,
    AnalyticsPlayedEventAction,
    AudioInputAudioEndReasonAction,
    AudioInputAudioPlayChangeAction,
    AudioInputAudioPositionResetAction,
    AudioInputAudioSrcChangeAction,
    AudioInputAudiosChangeAction,
    AudioInputSetAndPlayAction,
    AudioInputTypeChangeAction,
    AudioOutputAudioDurationChangeAction,
    AudioOutputAudioEndedAction,
    AudioOutputAudioFailedAction,
    AudioOutputAudioPlayChangeAction,
    BannerToggleAction,
    CastSetAvailableAction,
    ContextMenuCloseAction,
    ContextMenuCloseAllAction,
    ContextMenuHoverAction,
    ContextMenuOpenAction,
    ContextMenuSetVisibleAction,
    ContextMenuUpdatePositionAction,
    EnableLogTypesAction,
    EventMessageAction,
    FavoritesUpdateAction,
    LiveRadioNowAndRecentlyPlayedUpdateSection,
    LiveRadioSetAndPlayAction,
    LiveRadioUpdateAction,
    LoggingEventAnalyticsAction,
    LoggingEventMessageAction,
    LoggingEventPlaybackReportAction,
    LoggingEventPlaybackReportForceAction,
    LoggingEventPlaybackReportSentAction,
    MaintenanceDataUpdateAction,
    ModalCloseAction,
    SentryCutoffShown,
    ModalOpenAction,
    NavigatePageContextUpdateAction,
    NavigatedAction,
    NotificationFeedNotificationsConversionAction,
    NotificationFeedNotificationsSeenAction,
    NotificationPwaInstallMessageWaitingAction,
    PageClickAction,
    PlayQueueAddToLastAction,
    PlayQueueAddToQueueAction,
    PlayQueueClearAction,
    PlayQueueDragAddToQueueAction,
    PlayQueueEndlessPlayToggleAction,
    PlayQueueEndlessPlayUpdateAction,
    PlayQueueEndlessPlayUpdateTrackIdAction,
    PlayQueuePlayTrack,
    PlayQueuePriorityLaneWarningToggleAction,
    PlayQueuePlayTrackAction,
    PlayQueueRemoveTrackAction,
    PlayQueueUpdateAction,
    PlayQueueUpdateMixRadioSessionAction,
    PlayeSetMuteAction,
    PlayerConcurrencyLostAction,
    PlayerNextAction,
    PlayerPreviousAction,
    PlayerRepeatAction,
    PlayerRewindAction,
    PlayerSeekAction,
    PlayerUiSeekingAction,
    PlayerSetControllerAndOutputTypeAction,
    PlayerSetVolumeAction,
    PlayerShuffleAction,
    PlaylistCollectionAddedAction,
    PlaylistCreatedAction,
    PlaylistDeletedAction,
    PlaylistModifiedAction,
    PlaylistTrackAddedAction,
    PlaylistTrackRemovedAction,
    PlaylistTrackReorderedAction,
    RecentTracksAddIdAction,
    RecentTracksUpdateAction,
    RehydrateAction,
    RouterRedirectingAction,
    SetDesktopQueueOpenAction,
    SetFavoritesSortingAction,
    SetLocalizationAction,
    SetLocalizationSettingAction,
    SetMaxiPlayerOpenAction,
    SetMiniPlayerOpenAction,
    SetThemeSettingAction,
    SetUserDraggingAction,
    TabFocusChangeAction,
    ToastOpenAction,
    TracingNewTraceAction,
    UpdateAppAction,
    UpdateCachedPlaylistsAction,
    UpdateControlPanelAction,
    UserAddSearchHistoryAction,
    UserClickSearchResultAction,
    UserChangedAction,
    UserLoggedInAction,
    UserLoggingOutAction,
    UserLoginErrorAction,
    UserUpdatedTokensAction,
    PlayerPreviousOrRewindAction,
    PlayerSetPlayAction,
    PlayerToggleFavoriteAction,
    PlayerTogglePlayAction,
    NotificationFeedSetNotificationsAction,
    AudioInputPlayAudioAction,
    PlaylistTracksAddedAction,
    PlayqueueClearPriorityLaneAction,
    PlayqueueKeepPriorityLaneAction,
    SetMyplaylistsSelection,
    SetDesktopLyricsVisibleAction,
    SetMyPlaylistTracksSorting,
    PlaylistCancelledAddingDuplicateItemsAction,
    DisplayedLyricsAction,
    UserProfilesChangedAction,
    SetSoundQualitySettingAction,
    ProfileCreatedAction,
    ProfileUpdatedAction,
    ProfileDeletedAction
} from "./actionTypes";

// general input
export const AUDIO_INPUT_AUDIOS_CHANGE = "AUDIO_INPUT_AUDIOS_CHANGE";
export const AUDIO_INPUT_AUDIO_END_REASON = "AUDIO_INPUT_AUDIO_END_REASON";
export const AUDIO_INPUT_AUDIO_PLAY_CHANGE = "AUDIO_INPUT_AUDIO_PLAY_CHANGE";
export const AUDIO_INPUT_AUDIO_POSITION_RESET = "AUDIO_INPUT_AUDIO_POSITION_RESET";
export const AUDIO_INPUT_AUDIO_SRC_CHANGE = "AUDIO_INPUT_AUDIO_SRC_CHANGE";
export const AUDIO_INPUT_SET_AND_PLAY = "AUDIO_INPUT_SET_AND_PLAY";
export const AUDIO_INPUT_PLAY_AUDIO = "AUDIO_INPUT_PLAY_AUDIO";
export const AUDIO_INPUT_TYPE_CHANGE = "AUDIO_INPUT_TYPE_CHANGE";

// input/playQueue
export const PLAYQUEUE_ADD = "PLAYQUEUE_ADD";
export const PLAYQUEUE_ADD_TO_LAST = "PLAYQUEUE_ADD_TO_LAST";
export const PLAYQUEUE_CLEAR = "PLAYQUEUE_CLEAR";
export const PLAYQUEUE_CLEAR_PRIORITY_LANE = "PLAYQUEUE_CLEAR_PRIORITY_LANE";
export const PLAYQUEUE_KEEP_PRIORITY_LANE = "PLAYQUEUE_KEEP_PRIORITY_LANE";
export const PLAYQUEUE_DROP = "PLAYQUEUE_DROP";
export const PLAYQUEUE_PLAY_TRACK = "PLAYQUEUE_PLAY_TRACK";
export const PLAYQUEUE_REMOVE_TRACK = "PLAYQUEUE_REMOVE_TRACK";
export const PLAYQUEUE_SET_AND_PLAY = "PLAYQUEUE_SET_AND_PLAY";
export const PLAYQUEUE_PRIORITY_LANE_WARNING_TOGGLE = "PLAYQUEUE_PRIORITY_LANE_WARNING_TOGGLE";
export const PLAYQUEUE_UPDATE = "PLAYQUEUE_UPDATE";
export const PLAYQUEUE_UPDATE_MIXRADIO_SESSION = "PLAYQUEUE_UPDATE_MIXRADIO_SESSION";

// input/liveRadio
export const LIVERADIO_NOW_AND_RECENTLY_PLAYED_UPDATE = "LIVERADIO_NOW_AND_RECENTLY_PLAYED_UPDATE";
export const LIVERADIO_SET_AND_PLAY = "LIVERADIO_SET_AND_PLAY";
export const LIVERADIO_UPDATE = "LIVERADIO_UPDATE";

// input/playQueue/endlessPlay
export const PLAYQUEUE_ENDLESSPLAY_TOGGLE = "PLAYQUEUE_ENDLESSPLAY_TOGGLE";
export const PLAYQUEUE_ENDLESSPLAY_TRACK_ID_UPDATE = "PLAYQUEUE_ENDLESSPLAY_TRACK_ID_UPDATE";
export const PLAYQUEUE_ENDLESSPLAY_UPDATE = "PLAYQUEUE_ENDLESSPLAY_UPDATE";
export const PLAYQUEUE_HISTORY_TOGGLE = "PLAYQUEUE_HISTORY_TOGGLE";

// general output
export const AUDIO_OUTPUT_AUDIO_DURATION_CHANGE = "AUDIO_OUTPUT_AUDIO_DURATION_CHANGE";
export const AUDIO_OUTPUT_AUDIO_ENDED = "AUDIO_OUTPUT_AUDIO_ENDED";
export const AUDIO_OUTPUT_AUDIO_PLAY_CHANGE = "AUDIO_OUTPUT_AUDIO_PLAY_CHANGE";
export const AUDIO_OUTPUT_AUDIO_FAILED = "AUDIO_OUTPUT_AUDIO_FAILED";

// player
export const PLAYER_CONCURRENCY_LOST = "PLAYER_CONCURRENCY_LOST";
export const PLAYER_NEXT = "PLAYER_NEXT";
export const PLAYER_PREVIOUS = "PLAYER_PREVIOUS";
export const PLAYER_PREVIOUS_OR_REWIND = "PLAYER_PREVIOUS_OR_REWIND";
export const PLAYER_REPEAT = "PLAYER_REPEAT";
export const PLAYER_REWIND = "PLAYER_REWIND";
export const PLAYER_SEEK = "PLAYER_SEEK";
export const PLAYER_SET_CONTROLLER_AND_OUTPUT_TYPE = "PLAYER_SET_CONTROLLER_AND_OUTPUT_TYPE";
export const PLAYER_SET_MUTE = "PLAYER_SET_MUTE";
export const PLAYER_SET_PLAY = "PLAYER_SET_PLAY";
export const PLAYER_SET_VOLUME = "PLAYER_SET_VOLUME";
export const PLAYER_SHUFFLE = "PLAYER_SHUFFLE";
export const PLAYER_STARTED_BY_USER = "PLAYER_STARTED_BY_USER";
export const PLAYER_TOGGLE_FAVORITE = "PLAYER_TOGGLE_FAVORITE";
export const PLAYER_TOGGLE_PLAY = "PLAYER_TOGGLE_PLAY";
export const PLAYER_TOGGLE_VOLUME_EXPAND = "PLAYER_TOGGLE_VOLUME_EXPAND";
export const PLAYER_UI_SEEKING = "PLAYER_UI_SEEKING";

export const RECENT_TRACKS_UPDATE = "RECENT_TRACKS_UPDATE";
export const RECENT_TRACKS_ADD_ID = "RECENT_TRACKS_ADD_ID";

export const CLEAR_APP_STATE = "CLEAR_APP_STATE";
export const UPDATE_CONTROL_PANEL = "UPDATE_CONTROL_PANEL";
export const ENABLE_LOGTYPE = "ENABLE_LOGTYPE";

export const PAGE_CLICK = "PAGE_CLICK";

export const NAVIGATION_NAVIGATED = "NAVIGATION_NAVIGATED";
export const NAVIGATION_PAGECONTEXT_UPDATE = "NAVIGATION_PAGECONTEXT_UPDATE";

export const NOTIFICATION_BANNER_TOGGLE = "NOTIFICATION_BANNER_TOGGLE";
export const NOTIFICATION_MAINTENANCE_DATA_UPDATE = "NOTIFICATION_MAINTENANCE_DATA_UPDATE";
export const NOTIFICATION_PWA_INSTALL_MESSAGE_WAITING = "NOTIFICATION_PWA_INSTALL_MESSAGE_WAITING";
export const NOTIFICATION_WELCOME_MESSAGE_SHOWN = "NOTIFICATION_WELCOME_MESSAGE_SHOWN";

export const NOTIFICATION_FEED_SET_NOTIFICATIONS = "NOTIFICATION_FEED_SET_NOTIFICATIONS";
export const NOTIFICATION_FEED_NOTIFICATIONS_SEEN = "NOTIFICATION_FEED_NOTIFICATIONS_SEEN";
export const NOTIFICATION_FEED_NOTIFICATION_CONVERTED = "NOTIFICATION_FEED_NOTIFICATION_CONVERTED";

// chromecast
export const CAST_SET_AVAILABLE = "CAST_SET_AVAILABLE";

// context menu
export const CONTEXT_MENU_OPEN = "CONTEXT_MENU_OPEN";
export const CONTEXT_MENU_CLOSE = "CONTEXT_MENU_CLOSE";
export const CONTEXT_MENU_CLOSE_ALL = "CONTEXT_MENU_CLOSE_ALL";
export const CONTEXT_MENU_UPDATE_POSITION = "CONTEXT_MENU_UPDATE_POSITION";
export const CONTEXT_MENU_HOVER = "CONTEXT_MENU_HOVER";
export const CONTEXT_MENU_SET_VISIBLE = "CONTEXT_MENU_SET_VISIBLE";
export const CONTEXT_MENU_REPOSITION = "CONTEXT_MENU_REPOSITION";

export const MODAL_OPEN = "MODAL_OPEN";
export const MODAL_CLOSE = "MODAL_CLOSE";

// analytics
export const ANALYTICS_PLAYED_ERROR_EVENT = "ANALYTICS_PLAYED_ERROR_EVENT";
export const ANALYTICS_PLAYED_EVENT = "ANALYTICS_PLAYED_EVENT";
export const ANALYTICS_PLAYQUEUE_SAVE_TO_PLAYLIST = "PLAYQUEUE_SAVE_TO_PLAYLIST";
export const ANALYTICS_PLAYQUEUE_CLEAR_QUEUE = "ANALYTICS_PLAYQUEUE_CLEAR_QUEUE";

// app
export const UPDATE_APP_VERSION = "UPDATE_APP";
export const REHYDRATE = "REHYDRATE";

//user
export const USER_ADD_SEARCH_HISTORY = "USER_ADD_SEARCH_HISTORY";
export const USER_CLICK_SEARCH_RESULT = "USER_CLICK_SEARCH_RESULT";
export const USER_CLEAR_SEARCH_HISTORY = "USER_CLEAR_SEARCH_HISTORY";
export const USER_CHANGED = "USER_CHANGED";
export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const USER_LOGGING_OUT = "USER_LOGGING_OUT";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const USER_UPDATED_TOKENS = "USER_UPDATED_TOKENS";
export const USER_HAS_BAD_TOKENS = "USER_HAS_BAD_TOKENS";
export const USER_LOGIN_ERROR = "USER_LOGIN_ERROR";
export const SENTRY_CUTOFF_SHOWN = "SENTRY_CUTOFF_SHOWN";

//profile
export const USER_PROFILES_CHANGED = "USER_PROFILES_CHANGED";
export const PROFILE_CREATED = "PROFILE_CREATED";
export const PROFILE_UPDATED = "PROFILE_UPDATED";
export const PROFILE_DELETED = "PROFILE_DELETED";

export const ROUTER_REDIRECTING = "ROUTER_REDIRECTING";

//ui
export const SET_ALBUMS_SORTING = "SET_ALBUMS_SORTING";
export const SET_DESKTOP_QUEUE_OPEN = "SET_DESKTOP_QUEUE_OPEN";
export const SET_DESKTOP_LYRICS_VISIBLE = "SET_DESKTOP_LYRICS_VISIBLE";
export const DISPLAYED_LYRICS = "DISPLAYED_LYRICS";
export const SET_MY_MUSIC_SORTING = "SET_MY_MUSIC_SORTING";
export const SET_MY_PLAYLISTS_SELECTION = "SET_MY_PLAYLISTS_SELECTION";
export const SET_MY_PLAYLIST_TRACKS_SORTING = "SET_MY_PLAYLIST_TRACKS_SORTING";
export const SET_MAXIPLAYER_OPEN = "SET_MAXIPLAYER_OPEN";
export const SET_MINIPLAYER_OPEN = "SET_MINIPLAYER_OPEN";
export const SET_THEME_SETTING = "SET_THEME";
export const SET_BROWSER_LANGUAGE = "SET_BROWSER_LANGUAGE";
export const SET_LOCALIZATION_SETTING = "SET_LOCALIZATION_SETTING";
export const SET_SOUNDQUALITY_SETTING = "SET_SOUNDQUALITY_SETTING";
export const SET_USER_DRAGGING = "SET_USER_DRAGGING";
export const TOGGLE_MAXIPLAYER_QUEUE_OPEN = "TOGGLE_MAXIPLAYER_QUEUE_OPEN";

//logger
export const EVENT_MESSAGE = "EVENT_MESSAGE";
export const LOGGING_EVENT_MESSAGE = "LOGGING_EVENT_MESSAGE";
export const LOGGING_EVENT_ANALYTICS = "LOGGING_EVENT_ANALYTICS";

// playback report
export const LOGGING_EVENT_PLAYBACK_REPORT_FINISHED = "LOGGING_EVENT_PLAYBACK_REPORT_FINISHED";
export const LOGGING_EVENT_PLAYBACK_REPORT_FORCE_FINISHED = "LOGGING_EVENT_PLAYBACK_REPORT_FORCE_FINISHED";
export const LOGGING_EVENT_PLAYBACK_REPORT_SENT = "LOGGING_EVENT_PLAYBACK_REPORT_SENT";

//favorites
export const FAVORITES_UPDATED = "FAVORITES_UPDATED";
export const PLAYLIST_CREATED = "PLAYLIST_CREATED";
export const PLAYLIST_DELETED = "PLAYLIST_DELETED";
export const PLAYLIST_MODIFIED = "PLAYLIST_MODIFIED";
export const PLAYLIST_COLLECTION_ADDED = "PLAYLIST_COLLECTION_ADDED";
export const PLAYLIST_CANCELLED_ADDING_DUPLICATE_ITEMS = "PLAYLIST_CANCELLED_ADDING_DUPLICATE_ITEMS";
export const PLAYLIST_TRACK_ADDED = "PLAYLIST_TRACK_ADDED";
export const PLAYLIST_TRACKS_ADDED = "PLAYLIST_TRACKS_ADDED";
export const PLAYLIST_TRACKS_REMOVED = "PLAYLIST_TRACKS_REMOVED";
export const PLAYLIST_TRACK_REORDERED = "PLAYLIST_TRACK_REORDERED";

export const UPDATE_CACHED_PLAYLISTS = "UPDATE_CACHED_PLAYLISTS";

export const TOAST_OPEN = "TOAST_OPEN";
export const TOAST_CLOSE = "TOAST_CLOSE";

export const TAB_FOCUS_CHANGE = "TAB_FOCUS_CHANGE";

// tracing
export const TRACING_NEW_TRACE = "TRACING_NEW_TRACE";

// The actions that have no payload
type SimpleActionType =
    | typeof ANALYTICS_PLAYQUEUE_SAVE_TO_PLAYLIST
    | typeof ANALYTICS_PLAYQUEUE_CLEAR_QUEUE
    | typeof CLEAR_APP_STATE
    | typeof CONTEXT_MENU_REPOSITION
    | typeof NOTIFICATION_WELCOME_MESSAGE_SHOWN
    | typeof PLAYER_STARTED_BY_USER
    | typeof PLAYER_TOGGLE_VOLUME_EXPAND
    | typeof PLAYQUEUE_HISTORY_TOGGLE
    | typeof TOAST_CLOSE
    | typeof TOGGLE_MAXIPLAYER_QUEUE_OPEN
    | typeof USER_CLEAR_SEARCH_HISTORY
    | typeof USER_HAS_BAD_TOKENS
    | typeof USER_LOGGED_OUT;

// The actions that have a payload
type ComplexActionType =
    | typeof ANALYTICS_PLAYED_ERROR_EVENT
    | typeof ANALYTICS_PLAYED_EVENT
    | typeof AUDIO_INPUT_AUDIOS_CHANGE
    | typeof AUDIO_INPUT_AUDIO_END_REASON
    | typeof AUDIO_INPUT_AUDIO_PLAY_CHANGE
    | typeof AUDIO_INPUT_AUDIO_POSITION_RESET
    | typeof AUDIO_INPUT_AUDIO_SRC_CHANGE
    | typeof AUDIO_INPUT_PLAY_AUDIO
    | typeof AUDIO_INPUT_SET_AND_PLAY
    | typeof AUDIO_INPUT_TYPE_CHANGE
    | typeof AUDIO_OUTPUT_AUDIO_DURATION_CHANGE
    | typeof AUDIO_OUTPUT_AUDIO_ENDED
    | typeof AUDIO_OUTPUT_AUDIO_FAILED
    | typeof AUDIO_OUTPUT_AUDIO_PLAY_CHANGE
    | typeof CAST_SET_AVAILABLE
    | typeof CONTEXT_MENU_CLOSE
    | typeof CONTEXT_MENU_CLOSE_ALL
    | typeof CONTEXT_MENU_HOVER
    | typeof CONTEXT_MENU_OPEN
    | typeof CONTEXT_MENU_UPDATE_POSITION
    | typeof ENABLE_LOGTYPE
    | typeof EVENT_MESSAGE
    | typeof FAVORITES_UPDATED
    | typeof LIVERADIO_SET_AND_PLAY
    | typeof LIVERADIO_UPDATE
    | typeof LOGGING_EVENT_ANALYTICS
    | typeof LOGGING_EVENT_PLAYBACK_REPORT_FINISHED
    | typeof LOGGING_EVENT_PLAYBACK_REPORT_FORCE_FINISHED
    | typeof LOGGING_EVENT_PLAYBACK_REPORT_SENT
    | typeof MODAL_CLOSE
    | typeof MODAL_OPEN
    | typeof NAVIGATION_NAVIGATED
    | typeof NAVIGATION_PAGECONTEXT_UPDATE
    | typeof NOTIFICATION_BANNER_TOGGLE
    | typeof NOTIFICATION_FEED_NOTIFICATIONS_SEEN
    | typeof NOTIFICATION_FEED_NOTIFICATION_CONVERTED
    | typeof NOTIFICATION_MAINTENANCE_DATA_UPDATE
    | typeof NOTIFICATION_PWA_INSTALL_MESSAGE_WAITING
    | typeof PAGE_CLICK
    | typeof PLAYER_CONCURRENCY_LOST
    | typeof PLAYER_REPEAT
    | typeof PLAYER_SEEK
    | typeof PLAYER_UI_SEEKING
    | typeof PLAYER_SET_CONTROLLER_AND_OUTPUT_TYPE
    | typeof PLAYER_SET_MUTE
    | typeof PLAYER_SET_PLAY
    | typeof PLAYER_SET_VOLUME
    | typeof PLAYER_TOGGLE_FAVORITE
    | typeof PLAYER_TOGGLE_PLAY
    | typeof PLAYLIST_CREATED
    | typeof PLAYLIST_DELETED
    | typeof PLAYLIST_MODIFIED
    | typeof PLAYLIST_TRACKS_ADDED
    | typeof PLAYLIST_TRACKS_REMOVED
    | typeof PLAYLIST_TRACK_ADDED
    | typeof PLAYLIST_TRACK_REORDERED
    | typeof PLAYQUEUE_ADD
    | typeof PLAYQUEUE_ADD_TO_LAST
    | typeof PLAYQUEUE_CLEAR
    | typeof PLAYQUEUE_CLEAR_PRIORITY_LANE
    | typeof PLAYQUEUE_DROP
    | typeof PLAYQUEUE_ENDLESSPLAY_TOGGLE
    | typeof PLAYQUEUE_ENDLESSPLAY_TRACK_ID_UPDATE
    | typeof PLAYQUEUE_ENDLESSPLAY_UPDATE
    | typeof PLAYQUEUE_KEEP_PRIORITY_LANE
    | typeof PLAYQUEUE_PLAY_TRACK
    | typeof PLAYQUEUE_PRIORITY_LANE_WARNING_TOGGLE
    | typeof PLAYQUEUE_REMOVE_TRACK
    | typeof PLAYQUEUE_SET_AND_PLAY
    | typeof PLAYQUEUE_UPDATE
    | typeof PLAYQUEUE_UPDATE_MIXRADIO_SESSION
    | typeof PROFILE_CREATED
    | typeof PROFILE_UPDATED
    | typeof PROFILE_DELETED
    | typeof RECENT_TRACKS_ADD_ID
    | typeof RECENT_TRACKS_UPDATE
    | typeof REHYDRATE
    | typeof ROUTER_REDIRECTING
    | typeof SET_BROWSER_LANGUAGE
    | typeof SET_DESKTOP_QUEUE_OPEN
    | typeof SET_LOCALIZATION_SETTING
    | typeof SET_MAXIPLAYER_OPEN
    | typeof SET_MY_MUSIC_SORTING
    | typeof SET_THEME_SETTING
    | typeof SET_USER_DRAGGING
    | typeof TAB_FOCUS_CHANGE
    | typeof TRACING_NEW_TRACE
    | typeof UPDATE_APP_VERSION
    | typeof UPDATE_CONTROL_PANEL
    | typeof USER_CHANGED
    | typeof USER_LOGGED_IN
    | typeof USER_LOGGING_OUT
    | typeof USER_PROFILES_CHANGED
    | typeof USER_LOGIN_ERROR
    | typeof USER_UPDATED_TOKENS;

// Include the actions that contain a payload
export type ActionType = SimpleActionType | ComplexActionType;

export type Action =
    | AnalyticsPlayedErrorEventAction
    | AnalyticsPlayedEventAction
    | AudioInputAudioEndReasonAction
    | AudioInputAudioPlayChangeAction
    | AudioInputAudioPositionResetAction
    | AudioInputAudioSrcChangeAction
    | AudioInputAudiosChangeAction
    | AudioInputSetAndPlayAction
    | AudioInputPlayAudioAction
    | AudioInputTypeChangeAction
    | AudioOutputAudioDurationChangeAction
    | AudioOutputAudioEndedAction
    | AudioOutputAudioFailedAction
    | AudioOutputAudioPlayChangeAction
    | BannerToggleAction
    | CastSetAvailableAction
    | ContextMenuCloseAction
    | ContextMenuCloseAllAction
    | ContextMenuHoverAction
    | ContextMenuOpenAction
    | ContextMenuSetVisibleAction
    | ContextMenuUpdatePositionAction
    | EnableLogTypesAction
    | EventMessageAction
    | FavoritesUpdateAction
    | LiveRadioNowAndRecentlyPlayedUpdateSection
    | LiveRadioSetAndPlayAction
    | LiveRadioUpdateAction
    | LoggingEventAnalyticsAction
    | LoggingEventMessageAction
    | LoggingEventPlaybackReportAction
    | LoggingEventPlaybackReportForceAction
    | LoggingEventPlaybackReportSentAction
    | MaintenanceDataUpdateAction
    | ModalCloseAction
    | SentryCutoffShown
    | ModalOpenAction
    | NavigatePageContextUpdateAction
    | NavigatedAction
    | NotificationFeedSetNotificationsAction
    | NotificationFeedNotificationsConversionAction
    | NotificationFeedNotificationsSeenAction
    | NotificationPwaInstallMessageWaitingAction
    | PageClickAction
    | PlayQueueAddToLastAction
    | PlayQueueAddToQueueAction
    | PlayQueueClearAction
    | PlayQueueDragAddToQueueAction
    | PlayQueueEndlessPlayToggleAction
    | PlayQueueEndlessPlayUpdateAction
    | PlayqueueClearPriorityLaneAction
    | PlayqueueKeepPriorityLaneAction
    | PlayQueueEndlessPlayUpdateTrackIdAction
    | PlayQueuePriorityLaneWarningToggleAction
    | PlayQueuePlayTrack
    | PlayQueuePlayTrackAction
    | PlayQueueRemoveTrackAction
    | PlayQueueUpdateAction
    | PlayQueueUpdateMixRadioSessionAction
    | PlayeSetMuteAction
    | PlayerConcurrencyLostAction
    | PlayerNextAction
    | PlayerPreviousAction
    | PlayerPreviousOrRewindAction
    | PlayerRepeatAction
    | PlayerRewindAction
    | PlayerSeekAction
    | PlayerUiSeekingAction
    | PlayerSetControllerAndOutputTypeAction
    | PlayerSetPlayAction
    | PlayerSetVolumeAction
    | PlayerShuffleAction
    | PlayerToggleFavoriteAction
    | PlayerTogglePlayAction
    | PlaylistCollectionAddedAction
    | PlaylistCreatedAction
    | PlaylistDeletedAction
    | PlaylistCancelledAddingDuplicateItemsAction
    | PlaylistModifiedAction
    | PlaylistTracksAddedAction
    | PlaylistTrackAddedAction
    | PlaylistTrackRemovedAction
    | PlaylistTrackReorderedAction
    | ProfileCreatedAction
    | ProfileUpdatedAction
    | ProfileDeletedAction
    | RecentTracksAddIdAction
    | RecentTracksUpdateAction
    | RehydrateAction
    | RouterRedirectingAction
    | SetDesktopLyricsVisibleAction
    | DisplayedLyricsAction
    | SetDesktopQueueOpenAction
    | SetFavoritesSortingAction
    | SetMyPlaylistTracksSorting
    | SetMyplaylistsSelection
    | SetLocalizationAction
    | SetLocalizationSettingAction
    | SetSoundQualitySettingAction
    | SetMaxiPlayerOpenAction
    | SetMiniPlayerOpenAction
    | SetThemeSettingAction
    | SetUserDraggingAction
    | TabFocusChangeAction
    | ToastOpenAction
    | TracingNewTraceAction
    | UpdateAppAction
    | UpdateCachedPlaylistsAction
    | UpdateControlPanelAction
    | UserAddSearchHistoryAction
    | UserClickSearchResultAction
    | UserChangedAction
    | UserLoggedInAction
    | UserLoggingOutAction
    | UserLoginErrorAction
    | UserProfilesChangedAction
    | UserUpdatedTokensAction
    | { type: SimpleActionType };
