import { WebAppBrand } from "shared/models";
import "theme.scss";
import { appInit } from "global/config";
import type { BuildEnvironment } from "services/environment";
import { getEnvironmentConfigYouSeeDefault } from "services/environment";
import * as Danish from "generated/localization-da-yousee.json";
import * as English from "generated/localization-en-yousee.json";

const environment: BuildEnvironment = {
    environment: { ...getEnvironmentConfigYouSeeDefault(), webAppBrand: WebAppBrand.YouSee },
    translations: { Danish, English }
};
appInit(environment);
