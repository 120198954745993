import { h } from "preact";
import type { ModalButton } from "..";
import { Modal, dismissModal, modalButtonSubmitOK, openModal } from "..";
import { dispatch } from "global";
import { USER_LOGIN_ERROR } from "global/actions";
import { useTranslations } from "global/config";
import { ButtonDesign } from "components/atoms/controls/button";
import { showLoginUi } from "components/organisms/login";
import { useInviteFlowHelpUrl } from "services/backend";

export function showLoginErrorModal(errorcode: number, hasBeenPoked?: boolean) {
    dispatch({
        type: USER_LOGIN_ERROR,
        payload: {
            error: errorcode
        }
    });
    return openModal(errorcode === 3 ? <NoMusicRightsModal hasBeenPoked={hasBeenPoked} /> : <GeneralLoginErrorModal />);
}

function GeneralLoginErrorModal() {
    const translations = useTranslations();
    return <Modal hasXCloseButton title={translations.LoginErrorHeader} text={translations.LoginErrorTextSomethingWentWrong} buttons={[modalButtonSubmitOK()]} />;
}

function NoMusicRightsPokedBeforeModal() {
    const translations = useTranslations();
    const { model: inviteFlowReadMoreLink } = useInviteFlowHelpUrl({});
    const onDismissal = () => {
        dismissModal("InviteFlowModal");
    };

    const noMusicRightsButtons: ModalButton[] = [
        {
            title: "GlobalCloseName",
            action: onDismissal,
            design: ButtonDesign.TextBrandMedium
        },
        {
            title: "SentryCutOffNoMusicRightsReadMoreOption",
            action: () => {
                window.open(inviteFlowReadMoreLink as string, "_blank");
            },
            design: ButtonDesign.PrimarySmall
        }
    ];
    return <Modal title={translations.SentryCutOffNoMusicRightsTitle} text={translations.SentryCutOffNoMusicRightsSubtitle} buttons={noMusicRightsButtons} />;
}

function NoMusicRightsModal({ hasBeenPoked }: { hasBeenPoked?: boolean }) {
    const noMusicRightsButtons: ModalButton[] = [
        {
            title: "LoginWithAnotherLogin",
            action: () => {
                dismissModal("LoginWithAnotherLogin");
                showLoginUi();
            },
            design: ButtonDesign.TextBrandMedium
        }
    ];
    const translations = useTranslations();
    return hasBeenPoked ? (
        <NoMusicRightsPokedBeforeModal />
    ) : (
        <Modal hasXCloseButton title={translations.LoginNoMusicRights} text={translations.LoginNoMusicRightsSubTitle} buttons={noMusicRightsButtons} />
    );
}
