import { h } from "preact";
import "./Banner.scss";

interface BannerProps {
    message: string;
    open: boolean;
    button?: JSX.Element;
    serious?: boolean;
}
export const Banner = ({ button, message, open, serious }: BannerProps) => {
    return (
        <div className={`banner Y6s --center-${button == null} --show-${open} ${serious ? "serious" : ""}`}>
            <div className="message">{message}</div>
            {button}
        </div>
    );
};
